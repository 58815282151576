.banner {
    position: relative;
    padding-bottom: 120px;
}

.banner__bg {
    position: relative;
    left: -130px;
    margin-top: -120px;

    svg {
        width: 716px;
    }
}

.banner__maps {
    flex: 0 0 50%;
    max-width: 50%;
    display: flex;
    align-items: center;

    map,
    img {
        width: 100%;
    }
}

.banner__content__inner {
    max-width: 502px;
    margin: 0 auto;
    padding: 155px 0 0;
}

.banner__graan {
    position: absolute;
    right: 100px;
    bottom: -25px;
}

.banner__contact {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
}

.template-contact-data {
    .banner__content {
        flex: 0 0 53%;
        max-width: 53%;
    }

    .banner {
        padding-bottom: 100px;

        > .container-fluid {
            position: relative;
            z-index: 2;
        }

        &::after {
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';

            /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+64,ffffff+100&0+64,1+100 */
            background: -moz-linear-gradient(-45deg, rgba(255, 255, 255, 0) 64%, rgba(255, 255, 255, 1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(-45deg, rgba(255, 255, 255, 0) 64%, rgba(255, 255, 255, 1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(135deg, rgba(255, 255, 255, 0) 64%, rgba(255, 255, 255, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1); /* IE6-9 fallback on horizontal gradient */
        }
    }
}

.banner__list {
    margin: 0 0 30px;
    padding: 0;
    list-style: none;
    font-weight: 300;
    text-align: center;

    .email {
        text-decoration: underline;
    }

    .phone {
        color: $primarycolor;
    }
}
