@media screen and (max-width: 420px) {
    h1,
    h2,
    h3,
    .row--form__title,
    h4,
    h5,
    h6 {
        margin-bottom: 15px;
        font-size: 30px;
    }

    .template-galerij-data .row--gallery {
        grid-template-columns: 1fr;
    }

    .hand {
        font-size: 30px;
    }

    .faq__title {
        font-size: 30px;
    }

    .faq__title .hand {
        margin-top: -20px;
        font-size: 30px;
    }

    .btn {
        font-size: 12px;
    }

    .title__small h1,
    .title__small h2,
    .title__small h3,
    .title__small .row--form__title,
    .title__small h4,
    .title__small h5,
    .title__small h6 {
        font-size: 30px;
    }

    .title__small .hand {
        font-size: 30px;
    }

    .menu__center {
        max-width: 300px;
    }

    .menu__inner .nav .menu-item a {
        padding: 5px;
        font-size: 15px;
    }

    .menu__logo svg {
        width: 222px;
        height: 115px;
        margin-top: -30px;
    }

    .vlees__intro h1,
    .vlees__intro h2,
    .vlees__intro h3,
    .vlees__intro .row--form__title,
    .vlees__intro h4,
    .vlees__intro h5,
    .vlees__intro h6 {
        font-size: 30px;
    }

    .vlees__intro .hand {
        font-size: 30px;
    }

    .subtitle {
        font-size: 12px;
    }

    .content__block h1,
    .content__block h2,
    .content__block h3,
    .content__block .row--form__title,
    .content__block h4,
    .content__block h5,
    .content__block h6 {
        font-size: 30px;
    }

    .content__block .hand {
        font-size: 28px;
    }

    .app__title {
        font-size: 16px;
    }
}

@media screen and (max-width: 380px) {
    .menu__inner .nav .menu-item a {
        padding: 2px;
        font-size: 14px;
    }

    .menu__center {
        max-width: 250px;
    }

    .menu__number svg {
        width: 40px;
        height: 22px;
    }

    .header {
        padding: 0 15px;
    }

    .header__menu__text {
        font-size: 14px;
    }

    .header__menu {
        padding: 8px 12px;
    }

    .header__lang {
        right: 0;
    }

    .order__form {
        margin: 0 15px;
    }
}
