.accordion {
    margin-bottom: 30px;

    .card {
        margin-bottom: 12px;
        padding-bottom: 7px;
        border: none;
        border-bottom: 1px solid #2a565b !important;
        border-radius: 0 !important;
        background: transparent;

        &:last-of-type,
        &:not(:first-of-type):not(:last-of-type),
        &:first-child {
            border-radius: 0 !important;
        }
    }
}

.card-header {
    padding: 0;
    font-weight: 600;
    border-bottom: none;
    border-radius: 0 !important;
    background: transparent;

    a {
        display: block;
        padding: 0 0 5px;
        color: #fff !important;
        background-color: transparent !important;

        &::before {
            float: right;
            width: 15px;
            height: 15px;
            margin: 5px 3px 5px 15px;
            content: "";
            transition: all 0.3s;
            background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %23BE6075;' focusable='false' data-prefix='fal' data-icon='angle-up' class='svg-inline--fa fa-angle-up fa-w-8' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 512'%3E%3Cpath fill='currentColor' d='M136.5 185.1l116 117.8c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L128 224.7 27.6 326.9c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17l116-117.8c4.7-4.6 12.3-4.6 17 .1z'%3E%3C/path%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 20px 24px;
        }

        &.collapsed::before {
            transform: rotate(-180deg);
        }
    }
}

.card-body {
    padding: 0;
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}
