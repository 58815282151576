.btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-right: 15px;
    padding: 15px 25px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3.2px;
    color: $primarycolor;
    border: 0;
    border-radius: 0;

    svg {
        width: 16px;
        margin-right: 12px;
        color: $secondarycolor;

        &.fa-cow {
            width: 22px;
        }

        &.bell {
            width: 20px;
            height: 20px;
        }

        &.fa-steak {
            position: relative;
            top: 2px;
            width: 19px;
            transform: scale(-1) rotate(45deg);
        }
    }

    &:hover,
    &:active,
    &:focus {
        color: $primarycolor;
        box-shadow: none !important;
    }

    &:last-child {
        margin-right: 0;
    }
}

.btn--primary {
    background-color: $primarycolor;
    text-decoration: none;
    color: #fff !important;

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
        text-decoration: none;
        color: #fff !important;
    }
}

.btn--secondary {
    color: #fff !important;
    background-color: #be6075;

    &:hover,
    &:active,
    &:focus {
        color: #fff !important;
        background-color: lighten(#be6075, 5) !important;
    }
}

.btn--next {
    padding-right: 38px;
    background-color: $primarycolor;

    &::after {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 7px;
        height: 18px;
        margin-top: -9px;
        content: '';
        background-image: url('../../images/angle-right-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--prev {
    padding-left: 40px;
    background-color: $primarycolor;

    &::before {
        position: absolute;
        top: 50%;
        left: 17px;
        width: 7px;
        height: 18px;
        margin-top: -9px;
        content: '';
        background-image: url('../../images/angle-left-regular-white.svg');
        background-size: 7px 18px;
    }

    &:hover,
    &:active,
    &:focus {
        background-color: lighten($primarycolor, 5) !important;
    }
}

.btn--open {
    transition: all 0.3s;
    color: #fff;
    border: 1px solid #fff;
    background-color: transparent;

    &:hover,
    &:active,
    &:focus {
        color: #fff !important;
        border-color: #fff;
        background-color: transparent;
    }
}

.btn-danger {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    svg {
        width: 15px;
        height: 15px;
        margin-left: 10px;
    }
}

.btn--border {
    position: relative;
    padding: 10px 20px;
    border: 3px solid #e6ebeb;

    &.btn--border--dark {
        color: #fff;
        border-color: #1a5e6b;

        &::before {
            background-color: $primarycolor;
        }

        &::after {
            background-color: $primarycolor;
        }
    }

    &.btn--border--light {
        color: $primarycolor;
        border-color: #fff;

        &::before {
            background-color: #e6ebeb;
        }

        &::after {
            background-color: #e6ebeb;
        }
    }

    &::before {
        position: absolute;
        top: -3px;
        left: 20%;
        display: inline-block;
        width: 17px;
        height: 3px;
        content: '';
        transition: all 200ms ease-in-out;
        background-color: #fff;
    }

    &::after {
        position: absolute;
        right: 20%;
        bottom: -3px;
        display: inline-block;
        width: 17px;
        height: 3px;
        content: '';
        transition: all 200ms ease-in-out;
        background-color: #fff;
    }

    &:hover,
    &:focus {
        &::before {
            left: 68%;
            transition: all 200ms ease-in-out;
        }

        &::after {
            right: 68%;
            transition: all 200ms ease-in-out;
        }
    }
}
