@media screen and (max-width: 575px) {
    .title__small h1,
    .title__small h2,
    .title__small h3,
    .title__small .row--form__title,
    .title__small h4,
    .title__small h5,
    .title__small h6 {
        margin-bottom: 15px;
        font-size: 36px;
    }

    .galerij__intro {
        margin: 180px 33px 50px;
    }

    .title__small .hand {
        margin-top: -5px;
        font-size: 36px;
    }

    .kiezen__inner {
        padding: 30px;
    }

    .faq__title {
        font-size: 36px;
    }

    .faq__title .hand {
        font-size: 36px;
    }

    .vlees {
        margin-bottom: 60px;
    }

    .blog__slider__item {
        width: 100%;
    }

    .banner__contact {
        top: 130px;
        left: -310px;
    }

    .order__form {
        padding: 20px;
    }

    .contact__form__inner {
        margin: 0 20px 130px;
        padding: 20px 20px;
    }

    .template-contact-data .banner__content {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .single__date {
        width: 138px;
        height: 40px;
        font-size: 12px;
    }

    .vlees__lap {
        right: 50%;
        margin-right: 0;
        margin-bottom: -160px;
        transform: translateX(50%);
    }

    .footer__plant {
        right: 0;
        max-width: 100px;
    }

    .vlees__bg {
        right: -310px;
    }

    .vlees__block {
        margin-bottom: 110px;
    }

    .front-page-data .footer__logo {
        padding: 150px 0 50px;
    }

    .footer__logo {
        svg {
            width: 114px;
            height: 100px;
        }
    }

    .menu__center {
        max-width: 350px;
    }

    .menu__number svg {
        width: 50px;
        height: 60px;
    }

    .footer .footer__menu a::after {
        display: none;
    }

    .footer .footer__menu {
        flex-direction: column;
    }

    .footer__menu .nav {
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .banner__bg {
        left: -266px;
        margin-top: -50px;
    }

    .banner__bg svg {
        width: 300px;
        height: 100px;
    }

    .menu__close {
        top: 10px;
        right: 10px;
        width: 45px;
        height: 45px;
    }

    .google {
        margin: -130px 0 50px;
    }
}
