.order__form__submit {
    svg {
        color: #e2b4be;
    }
}

.order__form {
    padding: 80px 75px;
    border: 2px solid #e6ebeb;
    background-color: #fff;

    label {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 600;
    }

    .form-control {
        min-height: 50px;
        font-size: 15px;
        border: 3px solid #e6ebeb;
        border-radius: 0;
    }
}

.order__block__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #be6075;

    svg {
        margin-right: 14px;
    }

    &.order__block__title--mb {
        margin-bottom: -15px;
    }
}

.form__block--address {
    margin-top: -50px;
}

.form__block--info {
    padding-top: 25px;
}

.form__info__block {
    margin-bottom: 90px;

    &:last-child {
        margin-bottom: 0;
    }
}

.form__block {
    margin-bottom: 60px;
}

.package__title {
    position: relative;

    &::after {
        margin: 0 0 0 5px;
        content: '-';
    }
}

.modal__close {
    position: absolute;
    top: -25px;
    right: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 12px;
    opacity: 1;
    border: none;
    border-radius: 100%;
    background-color: $secondarycolor;

    &:focus {
        outline: none;
    }
}

.modal__option__list {
    margin-top: 40px;
    margin-bottom: 40px;
}

.modal__option {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0;
    }
}

.modal__label {
    width: 110px;
    font-weight: bold;
}

.modal__bot {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 55px;
    }

    .hand {
        font-size: 70px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 48%;
            font-size: 16px;
        }
    }
}

.modal-title {
    margin-bottom: 30px;
    font-family: $font-secondary;
    font-size: 28px;
    font-weight: bold;
    color: $secondarycolor;
}

.modal-content {
    background-color: #e6ebeb;
}

.modal__outer {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.modal__inner {
    position: relative;
    overflow: hidden;
}

.modal-body {
    z-index: 1;
    padding: 70px;
}

.modal-steak {
    position: absolute;
    top: 50px;
    right: -50px;

    svg {
        width: 190px;
        transform: rotate(-140deg);
        color: #dae1e2;
    }
}

@media (min-width: 576px) {
    .order__form .modal-dialog {
        width: 100%;
        max-width: 650px;
        margin: 1.75rem auto;
    }
}

.package__info {
    cursor: pointer;
    text-decoration: underline;
    color: #be6075;
}

.order__table {
    font-size: 15px;
    color: $primarycolor;

    .table {
        color: $primarycolor;
    }

    thead {
        th {
            border-top: 0;
            border-bottom: 1px solid #e6ebeb;
        }
    }

    .form-control {
        border: 1px solid #7b9396;
        border-radius: 0;
    }

    td,
    th {
        padding: 0.75rem 0.75rem 0.75rem 0;
        vertical-align: middle;

        &:last-child {
            padding-right: 0;
            text-align: right;
        }
    }

    th {
        font-size: 14px;
    }

    tr {
        &:last-child {
            > td {
                border-bottom: 1px solid #e6ebeb;
            }
        }
    }

    b {
        font-weight: 500;
    }
}

.order__form__half {
    flex: 0 0 48%;
    max-width: 48%;
}

.order__table--small {
    .order__price {
        width: 85px;
    }

    .order__count,
    .order__count__grams {
        width: 85px;
    }
}

.order__default {
    width: 125px;
}

.order__kg {
    width: 82px;
}

.order__price {
    width: 95px;
}

.order__count,
.order__count__grams {
    width: 125px;

    input {
        width: 60px;
        height: 35px;
        min-height: 35px !important;
        padding-right: 0;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

.order__total {
    text-align: right;
}

.order__total__price {
    width: 125px;
}

.order__no__border {
    border-bottom: 1px solid transparent !important;
}

.order__block__title--dark {
    color: $primarycolor;
}

.order__small {
    font-size: 13px;
    text-align: right;

    span {
        display: inline-flex;
        max-width: 200px;
    }
}

.row {
    &.row--form {
        margin-right: -10px;
        margin-left: -10px;
    }
}

.col--form {
    padding-right: 10px;
    padding-left: 10px;
}

.order__form__required {
    color: rgba($primarycolor, 0.4);
}

.col--form--required {
    margin-bottom: 30px;
    font-size: 13px;
    font-weight: 300;
}
