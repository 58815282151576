body {
    position: relative;
    font-family: $font-primary;
    font-size: $body-font-size;
    font-weight: $body-font-weight;
    line-height: $body-line-height;
    color: $body-font-color;
}

.admin-bar {
    .header__fix .header__menu__fixed {
        top: 32px;
    }
}

.main {
    position: relative;
    overflow: hidden;
    padding: 0;
}

.layer {
    padding: 80px 0;
}

.error404 {
    .layer {
        padding-top: 250px;
    }
}

.hidden {
    opacity: 0;
}

p {
    margin-bottom: 20px;

    a {
        text-decoration: underline;
        color: #be6075;

        &:hover,
        &:focus {
            &:not(.btn) {
                color: #be6075;
            }
        }
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 30px;
    font-family: $font-secondary;
    font-size: 76px;
    line-height: 1.1;
    color: $secondarycolor;
}

.subtitle {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3.2px;
    color: $primarycolor;
}

.hand {
    position: relative;
    z-index: -1;
    display: block;
    margin-top: -16px;
    font-family: $font-third;
    font-size: 120px;
    font-weight: initial;
    color: $primarycolor;
}

.title__small {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 54px;
    }

    .hand {
        font-size: 68px;
    }
}

.body__logo {
    position: absolute;
    z-index: -1;
    overflow: hidden;
    width: 100%;
    height: 860px;
    pointer-events: none;

    &.left__bottom {
        bottom: 0;
        left: 0;

        svg {
            top: 0;
            left: -290px;
        }
    }

    &.right__top {
        top: 0;
        left: 0;

        svg {
            top: 0;
            right: -290px;
        }
    }

    &.left__center {
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        svg {
            left: -184px;
            width: 368px;
            height: 492px;
        }
    }

    &.right__center {
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        svg {
            right: -184px;
            width: 368px;
            height: 492px;
        }
    }

    svg {
        position: absolute;
        top: 111px;
        width: 580px;
        height: auto;
        pointer-events: all;
    }

    path {
        transition: all 300ms ease-in-out;
        opacity: 1;
        fill: #7b9396;
    }
}

.svg-inline--fa {
    width: 30px;
    height: auto;
}

ul {
    &.check {
        margin: 0 0 16px 0;
        padding: 0;
        list-style: none;

        li {
            position: relative;
            margin-bottom: 2px;
            padding-left: 30px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                position: absolute;
                top: 4px;
                left: 0;
                width: 28px;
                height: 28px;
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' style='color: %238B4C59;' focusable='false' data-prefix='far' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z'%3E%3C/path%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 18px;
            }
        }
    }
}

a {
    transition: all 300ms ease-in-out;

    &:hover,
    &:focus {
        text-decoration: none;
        color: $body-font-color;
    }
}

.acf-map {
    width: 100%;
    height: 500px;
    margin: 0;
}

.acf-map img {
    max-width: inherit !important;
}

.archive__content {
    padding: 200px 0 0;
}

.archive__list {
    padding: 0 0 170px;
}

.slider__content {
    position: relative;

    a {
        position: relative;
        float: left;
    }
}

.slider__wrapper {
    overflow: hidden;
}

.slider__wrapper__top {
    position: relative;
}

.slider__play {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    pointer-events: none;

    svg {
        width: 50px;
        height: 50px;
        color: #fff;
    }
}

.slider__top__prev,
.slider__top__next {
    position: absolute;
    z-index: 9;
    top: 50%;
    margin-top: -40px;

    svg {
        width: 80px;
        height: 80px;
        cursor: pointer;
        color: rgba(#fff, 0.7);
    }
}

.slider__top__prev {
    left: 10px;
}

.slider__top__next {
    right: 10px;
}

.slick-slide {
    cursor: pointer;

    &.slick-current {
        cursor: default;
    }

    a {
        &:focus {
            outline: none !important;
        }
    }

    &:focus {
        outline: none !important;
    }

    img {
        border-radius: $border-radius;
        outline: none !important;

        &:focus {
            outline: none !important;
        }
    }
}

.slider__nav {
    margin: 10px -5px 0;
    padding: 0 1px;
}

.slider__nav__item {
    position: relative;
    padding: 0 4px;

    .slider__play {
        svg {
            width: 25px;
            height: 25px;
        }
    }
}

.block {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: calc(100% - 40px);
    margin-bottom: 40px;
    border: 1px solid #d5d5d5;
    border-radius: $block-radius;
    background-color: $white;

    &:hover {
        img {
            transform: scale(1.05);
        }

        .block__readmore {
            background-color: lighten($primarycolor, 5) !important;
        }
    }
}

.block__img__inner {
    display: block;
    overflow: hidden;
    width: 100%;

    img {
        width: 100%;
        max-width: 100%;
        transition: all 0.3s ease;
    }
}

.block__content {
    display: flex;
    align-items: flex-start;
    flex: 1;
    flex-direction: column;
    padding: 35px 45px 40px 45px;
    color: $body-font-color;
    background-color: $white;

    .btn {
        position: relative;
        display: inline-block;
        margin-top: auto;
        transition: all 0.3s;
        font-size: 13px;

        svg {
            width: 7px;
            height: 18px;
            margin-top: 2px;
            margin-left: 10px;
            transition: all 0.3s ease;
        }
    }
}

.block__title {
    display: block;
    font-size: 19px;
    font-weight: 700;
    line-height: 1.3;
    color: $primarycolor;
}

.block__subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    font-weight: 600;
}

.block__phone,
.block__email {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 34px;
    word-break: break-all;

    svg {
        position: absolute;
        top: 4px;
        left: 0;
        width: 16px;
        height: 16px;
        color: $primarycolor;
        fill: $primarycolor;
    }
}

.block__phone {
    margin-bottom: 4px;
}

.block__no__img {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 150px;
    background-color: #f9f9f9;
}

.block__date {
    display: block;
    margin-top: 7px;
    font-size: 14px;
    font-weight: 700;
    color: #505050;

    svg {
        width: 12px;
        height: 13px;
        margin: -4px 8px 0 0;
        color: #505050;
    }
}

.block__summary {
    display: block;
    height: 100%;
    margin: 15px 0 25px;
    font-size: 16px;
    line-height: 1.5;
    color: $body-font-color;
}

.block__list {
    padding: 19px 0 0;
}

.block__item {
    position: relative;
    display: block;
    margin: 0 0 8px 20px;
    color: $body-font-color;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        top: 5px;
        left: -18px;
        width: 6px;
        height: 18px;
        content: "";
        background: url('../../images/angle-right-regular.svg') no-repeat;
        background-size: 6px 16px;
    }
}

.single-post-data {
    .single__content {
        padding: 0 0 140px;
    }
}

.single__content {
    padding: 200px 0 330px;

    .btn--prev {
        margin-top: 30px;
    }

    h3 {
        margin-top: 30px;
    }
}

.accordion {
    margin: auto;
}

// Custom checkbox styling
.checkbox {
    display: flex;
    align-items: center;
    text-align: left;
}

.checkbox__input {
    display: none;
}

.checkbox__label {
    position: relative;
    display: flex;
    margin: 0;
    padding-right: 20px;
    cursor: pointer;
    line-height: 1.5;

    &::before {
        position: relative;
        display: inline-block;
        width: 15px;
        min-width: 15px;
        height: 15px;
        margin: 6px 10px 0;
        content: "";
        cursor: pointer;
        border: 1px solid #d5d5d5;
        border-radius: 3px;
        background-color: transparent;
    }
}

.checkbox .checkbox__input:checked + .checkbox__label::after {
    position: absolute;
    top: 8px;
    left: 15px;
    display: block;
    width: 5px;
    height: 9px;
    content: "";
    transform: rotate(45deg);
    border: solid #0079bf;
    border-width: 0 2px 2px 0;
}

.project__detail h1 {
    margin-bottom: 7px;
}

.container-fluid {
    padding-right: 40px;
    padding-left: 40px;

    .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}

/* stylelint-disable */

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
    .block {
        display: block;
        flex: none;
    }

    .block__content {
        display: block;
    }
}

/* stylelint-enable */

@media (max-width: 1199px) {
    .block__content {
        padding: 25px 35px 30px;
    }

    .slider__top__prev,
    .slider__top__next {
        margin-top: -30px;
    }

    .slider__top__prev svg,
    .slider__top__next svg {
        width: 60px;
        height: 60px;
        cursor: pointer;
        color: rgba(255, 255, 255, 0.7);
    }

    .slider__top__prev {
        left: 10px;
    }

    .slider__top__next {
        right: 10px;
    }
}

@media (max-width: 767px) {
    body {
        font-size: 15px;
    }

    .layer {
        padding: 40px 0;
    }

    .layer.archive__list {
        padding-top: 0;
        padding-bottom: 90px;
    }

    .layer.archive__content {
        padding-top: 180px;
        padding-bottom: 0;
    }

    .block__summary {
        font-size: 15px;
    }

    .single__content {
        padding: 40px 0;
    }

    .single__content .btn--prev {
        margin-top: 10px;
    }
}

@media (max-width: 575px) {

}

@media (max-width: 374px) {
    .container-fluid {
        padding-right: 20px;
        padding-left: 20px;
    }
}
