.menu__block {
    position: fixed;
    z-index: 9990;
    top: 0;
    overflow: hidden;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    max-height: 100vh;
    pointer-events: none;
    opacity: 0;
    background-color: #7b9396;
}

.menu__close {
    position: absolute;
    z-index: 99;
    top: 44px;
    right: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 76px;
    height: 76px;
    cursor: pointer;
    border-radius: 100%;
    background-color: #748e92;

    svg {
        width: 36px;
        height: 36px;
    }
}

.menu__layer1 {
    position: fixed;
    z-index: 9991;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    pointer-events: none;
    border-radius: 0;
    background-color: $primarycolor;
}

.menu__layer2 {
    position: fixed;
    z-index: 9992;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    pointer-events: none;
    border-radius: 0;
    background-color: #e6ebeb;
}

.menu__center {
    display: flex;
    flex: 0 0 850px;
    flex-direction: column;
    max-width: 850px;
    align-items: center;
    justify-content: center;
}

.menu__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;

    svg {
        width: 230px;
        height: 307px;
        margin-top: -75px;
        color: #67858a;
    }
}

.menu__bar {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

.menu__number {
    position: absolute;
    z-index: 1;
    font-family: $font-primary;
    font-size: 100px;
    font-weight: 600;
    color: #fff;
}

.menu__image {
    width: 100%;
    height: 500px;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-size: cover;
    filter: grayscale(1);
}

.menu__bar--left {
    .menu__number {
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
    }

    .menu__image {
        background-position: center right;
    }
}

.menu__bar--right {
    .menu__number {
        top: 50%;
        left: 0;
        transform: translate(-50%, -50%);
    }

    .menu__image {
        background-position: center left;
    }
}

.menu__inner {
    padding: 0 65px;
    z-index: 99;

    .nav {
        display: flex;
        flex-direction: column;

        .menu-item {
            text-align: center;

            a {
                display: inline-flex;
                padding: 8px 10px;
                transition: all 0ms ease-in-out;
                font-size: 18px;
                text-transform: uppercase;
                letter-spacing: 3px;
                color: #00424e;

                &:hover {
                    transition: all 0ms ease-in-out;
                    color: #fff;
                }
            }
        }

        .current-menu-item {
            a {
                text-decoration: line-through;
                color: #00424e;
            }
        }
    }
}

.menu__social {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10vh;
}

.menu__social__item {
    margin: 0 10px;
}

.menu__layer__logo {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    pointer-events: none;
}
