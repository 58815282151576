.single__img__nieuws {
    position: relative;
    margin-bottom: 60px;
}

.blog__single {
    padding: 80px 110px;
    background-color: #e6ebeb;

    .btn--back {
        margin-bottom: 65px;
    }
}

.blog__single__title {
    max-width: 850px;
    margin-bottom: 65px;

    h1 {
        font-size: 45px;
        color: $primarycolor;
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 575px) {

}
