@media screen and (max-width: 1199px) {
    h1,
    h2,
    h3,
    .row--form__title,
    h4,
    h5,
    h6 {
        margin-bottom: 15px;
        font-size: 52px;
    }

    .template-galerij-data .row--gallery {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .header__lang {
        top: 20px;
        right: 50px;
        left: 100%;
        margin-left: 130px;
    }

    .title__small h1,
    .title__small h2,
    .title__small h3,
    .title__small .row--form__title,
    .title__small h4,
    .title__small h5,
    .title__small h6 {
        margin-bottom: 15px;
        font-size: 52px;
    }

    .title__small .hand {
        margin-top: -30px;
        font-size: 52px;
    }

    .faq__title {
        font-size: 52px;
    }

    .faq__title .hand {
        margin-top: -25px;
        font-size: 52px;
    }

    .omgeving {
        margin-bottom: 160px;
    }

    .omgeving__inner {
        margin-bottom: 40px;
        padding: 40px 40px;
    }

    .btn--omgeving {
        margin-top: 40px;
        font-size: 14px;
    }

    .faq {
        margin-bottom: 110px;
    }

    .blog__single__title h1 {
        font-size: 32px;
    }

    .btn {
        padding: 8px 14px;
        font-size: 13px;
    }

    .blog__single {
        padding: 40px 30px;
    }

    .single__date {
        left: -30px;
    }

    .template-contact-data .banner {
        padding-bottom: 40px;
    }

    .vlees__item__bot {
        padding: 30px 40px;
    }

    .vlees__bot__info {
        padding: 10px 0 20px;
    }

    .vlees__item__bot__title {
        font-size: 36px;
    }

    .vlees__item__bot__title .hand {
        font-size: 36px;
    }

    .vlees__item__bot__bg svg {
        width: 120px;
    }

    .vlees__bot__list li {
        width: 100%;
    }

    .template-heerlijk-vlees-data .vlees__bg {
        right: -320px;
        bottom: -20px;
    }

    .vlees__item__top {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
    }

    .vlees {
        margin-bottom: 60px;
    }

    .kiezen {
        margin-bottom: 60px;
    }

    .vlees__cow {
        flex: 0 0 100%;
        order: 2;
        max-width: 100%;
        padding: 60px 10px 15px;
    }

    .faq__inner {
        padding: 50px 40px;

        .footer__image__personen {
            display: table;
            margin: 40px auto 0;
        }
    }

    .template-heerlijk-vlees-data .banner {
        padding-top: 40px;
        padding-bottom: 60px;
    }

    .faq__vragen {
        margin-bottom: 60px;
    }

    .faq__content {
        max-width: 565px;
        margin: 0 auto;
        padding: 0;
        text-align: center;
    }

    .contact__form__inner {
        padding: 40px 30px;
    }

    .blog__single__title {
        max-width: 100%;
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .blog__single .btn--back {
        margin-bottom: 0;
    }

    .single__img__nieuws {
        margin-bottom: 35px;
    }

    .single__buttons {
        margin-top: 20px;
    }

    .banner__bg svg {
        width: 300px;
    }

    .body__logo.right__top svg {
        right: -100px;
        width: 200px;
    }

    .intro__content {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .intro__side {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .intro__side__content {
        margin: 0;
        padding: 40px 40px 30px;
    }

    .banner__bg {
        margin-top: -86px;
    }

    .intro__logo {
        display: none;
    }

    .intro__side__image {
        width: calc(100% + 20px);
    }

    .intro__bg {
        left: -260px;
    }

    .blog__slider__title h1,
    .blog__slider__title h2,
    .blog__slider__title h3,
    .blog__slider__title .row--form__title,
    .blog__slider__title h4,
    .blog__slider__title h5,
    .blog__slider__title h6 {
        font-size: 52px;
    }

    .blog__slider__item {
        width: 33%;
    }

    .blog__slider__outer .blog__content {
        padding-right: 10px;
        padding-left: 10px;
    }

    .blog__slider__outer {
        padding: 0 30px;
    }

    .vlees__plant {
        width: 140px;
    }

    .blog__slider__block {
        padding: 70px 0 100px;
    }

    .vlees__content {
        flex: 0 0 60%;
        max-width: 60%;
        padding: 50px 40px 100px 40px;
    }

    .vlees__image {
        width: 50%;
    }

    .vlees__lap {
        width: 300px;
        height: 200px;
        margin-right: -37px;
        margin-bottom: -77px;
    }

    .vlees__block {
        margin-bottom: 100px;
    }

    .image__block__right {
        padding: 50px 40px;
    }

    .image__block__right__bg {
        display: none;
    }

    .footer__image__personen {
        position: relative;
        right: 0;
        bottom: 0;
        max-width: 200px;
        margin-top: 30px;
    }

    .footer__image__graan {
        position: absolute;
        top: -140px;
        right: 50px;
        width: 100px;
    }

    .footer__front__text .hand {
        position: relative;
        top: 0;
        left: 0;
        font-size: 62px;
    }

    .image__block {
        margin-bottom: 70px;
    }

    .footer__front__inner {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 50px 40px;
    }

    .footer__bg {
        display: none;
    }

    .front-page-data .footer__logo {
        padding: 370px 0 80px;
    }

    .menu__image {
        width: 300px;
    }

    .vlees__graan {
        right: -150px;
    }

    .vlees__intro__right {
        padding: 40px 60px 60px 30px;
    }

    .vlees__bg {
        bottom: -50px;
    }

    .vlees__bg svg {
        height: 115px;
    }

    .vlees__intro {
        margin-bottom: 70px;
    }

    .content__inner {
        padding: 40px 40px 100px;
    }

    .content__vlees {
        max-width: 250px;
    }

    .footer__logo {
        padding: 80px 0;
    }

    .full {
        margin-bottom: 50px;
    }

    .block__img__date {
        width: 142px;
        height: 36px;
        font-size: 12px;
    }

    .order {
        padding: 40px 0;
    }

    .order__form {
        padding: 40px;
    }

    .order__form__half {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .order__table td,
    .order__table th {
        padding: 8px 8px 8px 0;
    }

    .order__block__title.order__block__title--mb {
        margin-bottom: 5px;
    }

    .order__block__title {
        margin-bottom: 5px;
    }

    .form__block {
        margin-bottom: 30px;
    }

    .app__nav {
        padding: 0 10px;
    }

    .app__nav a:nth-child(4) {
        display: none;
    }

    .google {
        margin: -180px 0 50px;
    }
}
