/**
 * WordPress Generated Classes
 * @see http://codex.wordpress.org/CSS#WordPress_Generated_Classes
 */

/** Media alignment */
.alignnone {
    max-width: 100%;
    height: auto;
    margin-right: 0;
    margin-left: 0;
}

.aligncenter {
    display: block;
    height: auto;
    margin: ($spacer / 2) auto;
}

.alignleft,
.alignright {
    height: auto;
    margin-bottom: ($spacer / 2);
}

@include media-breakpoint-up(sm) {
    .alignleft {
        float: left;
        margin-right: ($spacer / 2);
    }

    .alignright {
        float: right;
        margin-left: ($spacer / 2);
    }
}

/** Captions */
.wp-caption {
    @extend .figure;
}

.wp-caption img {
    @extend .figure-img;
    @extend .img-fluid;
}

.wp-caption-text {
    @extend .figure-caption;
}

/** Text meant only for screen readers */
.screen-reader-text {
    @extend .sr-only;
    @extend .sr-only-focusable;
}
