/** Import Bootstrap functions */
@import "~bootstrap/scss/functions";

$slick-loader-path: "~slick-carousel/slick/";
$slick-font-path: "~slick-carousel/slick/fonts/";

$primarycolor: #00424e;
$secondarycolor: #8b4c59;

$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Playfair Display', serif;
$font-third: 'Great Vibes', cursive;
$body-font-size: 16px;
$body-font-weight: 400;
$body-line-height: 1.8;
$body-font-color: $primarycolor;

// Headings: h1,h2,h3,h4,h5,h6
$headings-margin-bottom: 20px;
$headings-font-family: $font-primary;
$headings-font-weight: 600;
$headings-line-height: 1.3;
$headings-color: $primarycolor;

// Header:
$header-height: 130px;

$border-radius: 5px;
$block-radius: 8px;

$theme-colors: (
    primary: $primarycolor
);

/** Bootstrap navbar fix (https://git.io/fADqW) */
$navbar-dark-toggler-icon-bg: none;
$navbar-light-toggler-icon-bg: none;

//Bootstrap
$grid-gutter-width: 40px !default;

$enable-responsive-font-sizes: true;
$h1-font-size: 36px;
$h2-font-size: 30px;
$h3-font-size: 24px;
$h4-font-size: 18px;
$h5-font-size: 18px;
