.single__sidebar {
    > div {
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
}

.sidebar__contact,
.sidebar__nieuws,
.sidebar__submenu .pagenav {
    display: block;
    padding: 34px 45px 45px;
    border-radius: $block-radius;
    background-color: #f3f3f3;
}

.sidebar__contact {
    .btn {
        display: block;
        margin-right: 0;
        text-align: left;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.sidebar__title {
    display: block;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    color: $primarycolor;
}

.sidebar__contact__text {
    display: block;
    margin-bottom: 20px;
}

.sidebar__nieuws__item {
    position: relative;
    display: block;
    margin-right: 0;
    padding-bottom: 12px;
    font-weight: 600;
    text-align: left;
    color: $body-font-color;
    border-bottom: 1px solid #ddd;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    &:hover {
        color: $body-font-color;

        .sidebar__nieuws__arrow {
            right: 2px;
        }
    }
}

.sidebar__nieuws__title {
    display: block;
    overflow: hidden;
    padding-right: 25px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $body-font-color;
}

.sidebar__nieuws__date {
    display: block;
    margin-top: 2px;
    padding-right: 25px;
    font-size: 13px;
    color: $body-font-color;

    svg {
        width: 12px;
        height: 13px;
        margin: -4px 8px 0 0;
        color: $body-font-color;
    }
}

.sidebar__nieuws__arrow {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -2px;
    transition: all 0.3s ease;
    transform: translateY(-50%);

    svg {
        width: 8px;
        height: 18px;
        color: $body-font-color;
        fill: $body-font-color;
    }
}

.sidebar__submenu {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .post__parent__title {
        display: block;
        margin-bottom: 15px;
        font-size: 19px;
        font-weight: 600;
        color: $primarycolor;
    }

    .page_item {
        a {
            position: relative;
            display: block;
            overflow: hidden;
            margin-bottom: 10px;
            padding-right: 25px;
            padding-bottom: 10px;
            font-weight: 600;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: $body-font-color;
            border-bottom: 1px solid #ddd;

            &::after {
                position: absolute;
                top: 50%;
                right: 5px;
                width: 7px;
                height: 18px;
                margin-top: -13px;
                content: '';
                // KLEUR VERANDEREN: in background data image: je ziet hier een style='color: ;'. Plaats de kleurcode achter %23. (%23 staat voor #)
                transition: all 0.3s;
                background-image: url("data:image/svg+xml,%3Csvg style='color: %23505050;' aria-hidden='true' focusable='false' data-prefix='far' data-icon='angle-right' class='svg-inline--fa fa-angle-right fa-w-6' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 192 512'%3E%3Cpath fill='currentColor' d='M187.8 264.5L41 412.5c-4.7 4.7-12.3 4.7-17 0L4.2 392.7c-4.7-4.7-4.7-12.3 0-17L122.7 256 4.2 136.3c-4.7-4.7-4.7-12.3 0-17L24 99.5c4.7-4.7 12.3-4.7 17 0l146.8 148c4.7 4.7 4.7 12.3 0 17z'%3E%3C/path%3E%3C/svg%3E");
                background-size: 7px 18px;
            }

            &:hover {
                &::after {
                    right: 2px;
                }
            }
        }

        &.current_page_item {
            > a {
                color: $primarycolor;
            }
        }

        &:last-child {
            a {
                margin-bottom: 0;
            }
        }
    }
}

@media screen and (max-width: 1199px) {

}

@media screen and (max-width: 991px) {
    .single__sidebar {
        max-width: 400px;
        margin-top: 40px;
    }
}

@media screen and (max-width: 767px) {
    .sidebar__contact,
    .sidebar__nieuws,
    .sidebar__submenu .pagenav {
        padding: 23px 35px 33px;
    }

    .sidebar__title {
        margin-bottom: 13px;
        font-size: 21px;
    }
}

@media screen and (max-width: 575px) {

}
