@media screen and (max-width: 1700px) {
    .vlees__intro__left {
        width: 33%;
    }

    .intro__logo {
        top: 30px;
        width: 160px;
        margin-left: -80px;
    }

    .intro__logo svg {
        width: 100%;
    }
}

@media screen and (max-width: 1520px) {
    body {
        font-size: 15px;
    }

    .galerij__intro {
        margin: 170px 0 100px;
    }

    h1,
    h2,
    h3,
    .row--form__title,
    h4,
    h5,
    h6 {
        font-size: 62px;
    }

    .hand {
        margin-top: -10px;
        font-size: 60px;
    }

    .blog__slider__title h1 .hand,
    .blog__slider__title h2 .hand,
    .blog__slider__title h3 .hand,
    .blog__slider__title .row--form__title .hand,
    .blog__slider__title h4 .hand,
    .blog__slider__title h5 .hand,
    .blog__slider__title h6 .hand {
        font-size: 68px;
    }

    .subtitle {
        margin-bottom: 10px;
        font-size: 14px;
    }

    .vlees__item__bot__title {
        font-size: 42px;
    }

    .vlees__item__bot__title .hand {
        font-size: 42px;
    }

    .vlees__bot__list li {
        width: 49%;
        font-size: 14px;
    }

    .menu__logo svg {
        width: 230px;
        height: 184px;
        margin-top: -35px;
        color: #67858a;
    }

    .vlees__item__bot {
        padding: 40px 50px;
    }

    .vlees__item__top {
        margin-bottom: 20px;
    }

    .template-heerlijk-vlees-data .banner {
        padding-top: 40px;
        padding-bottom: 100px;
    }

    .template-heerlijk-vlees-data {
        .vlees__bg {
            right: -300px;
            bottom: -55px;
        }
    }

    .omgeving__inner {
        margin-bottom: 50px;
        padding: 70px 70px;
    }

    .omgeving__inner .hand {
        font-size: 70px;
    }

    .vlees__info__list li span:first-child {
        width: 214px;
    }

    .vlees {
        margin-bottom: 90px;
    }

    .kiezen {
        margin-bottom: 80px;
    }

    .faq__inner {
        padding: 60px 80px;
    }

    .vlees__cow {
        flex: 0 0 230px;
        max-width: 230px;
        padding: 20px 20px 30px 38px;
    }

    .vlees__item {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .intro__content {
        padding-top: 20px;
        padding-bottom: 200px;

        h1,
        h2,
        h3,
        .row--form__title,
        h4,
        h5,
        h6 {
            margin-bottom: 20px;
            font-size: 42px;

            .hand {
                margin-top: -5px;
                font-size: 42px;
            }
        }
    }

    .blog__single__title h1 {
        font-size: 38px;
    }

    .blog__single {
        padding: 60px;
    }

    .single__date {
        left: -60px;
    }

    .blog__single .btn--back {
        margin-bottom: 50px;
    }

    .single__content {
        padding: 150px 0 190px;
    }

    .template-contact-data .banner {
        padding-bottom: 70px;
    }

    .banner__contact {
        left: -150px;
    }

    .intro__content .btn,
    .intro__content .comment-form input[type="submit"],
    .comment-form .intro__content input[type="submit"],
    .intro__content .gform_button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .contact__form__inner {
        padding: 50px;
    }

    .banner__content__buttons {
        margin-top: 20px;
    }

    .intro__side__content .btn,
    .intro__side__content .comment-form input[type="submit"],
    .comment-form .intro__side__content input[type="submit"],
    .intro__side__content .gform_button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .blog__slider__outer {
        left: 0 !important;
        margin-left: 0;
        padding: 0 20px;
    }

    .blog__block {
        padding: 30px;
    }

    .blog__image {
        margin-bottom: 20px;
    }

    .blog__title {
        margin-bottom: 10px;
    }

    .block__img__date {
        bottom: 20px;
        left: -30px;
        width: 180px;
        height: 38px;
        font-size: 14px;
    }

    .vlees__plant {
        left: 40px;
        width: 240px;
    }

    .blog__slider__block {
        padding: 70px 0 180px;
    }

    .body__logo {
        height: 550px;
    }

    .body__logo svg {
        width: 300px;
    }

    .body__logo.right__top svg {
        right: -150px;
    }

    .body__logo.left__bottom svg {
        left: -150px;
    }

    .banner {
        padding-bottom: 70px;
    }

    .banner__graan {
        position: absolute;
        right: 100px;
        bottom: -30px;
        width: 80px;
    }

    .intro__side__content .subtitle {
        margin-bottom: 20px;
    }

    .banner__content__inner {
        max-width: 420px;
        padding: 100px 0 0;
    }

    .header__logo {
        width: 240px;
        height: 250px;

        img {
            width: 80%;
        }

        > a {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .header__menu {
        margin-left: 125px;
    }

    .intro__side__content {
        padding: 70px 15px 70px 72px;
    }

    .vlees__content {
        padding: 70px 50px 120px 50px;
    }

    .vlees__lap {
        width: 400px;
        height: 276px;
        margin-right: -100px;
        margin-bottom: -125px;
    }

    .vlees__title {
        left: 0;
    }

    .vlees__title h1,
    .vlees__title h2,
    .vlees__title h3,
    .vlees__title .row--form__title,
    .vlees__title h4,
    .vlees__title h5,
    .vlees__title h6 {
        margin-bottom: 30px;
        font-size: 50px;
    }

    .archive__content {
        padding: 80px 0 0;
    }

    .vlees__title h1 .hand,
    .vlees__title h2 .hand,
    .vlees__title h3 .hand,
    .vlees__title .row--form__title .hand,
    .vlees__title h4 .hand,
    .vlees__title h5 .hand,
    .vlees__title h6 .hand {
        font-size: 60px;
    }

    .vlees__block {
        margin-bottom: 150px;
    }

    .content__block .btn,
    .content__block .comment-form input[type="submit"],
    .comment-form .content__block input[type="submit"],
    .content__block .gform_button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .blog__slider__item {
        width: 30%;
    }

    .image__block__right {
        padding: 70px;
    }

    .image__block__right .btn,
    .image__block__right .comment-form input[type="submit"],
    .comment-form .image__block__right input[type="submit"],
    .image__block__right .gform_button {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .footer__front__text .hand {
        top: -60px;
        font-size: 90px;
    }

    .image__block {
        margin-bottom: 140px;
    }

    .footer__front__inner {
        padding: 80px 285px 70px 70px;
    }

    .footer__image__personen {
        right: -80px;
        bottom: -38px;
        max-width: 320px;
    }

    .front-page-data .footer__logo {
        padding: 420px 0 80px;
    }

    .menu__center {
        max-width: 700px;
    }

    .vlees__intro__right {
        padding: 70px 50px 100px;
    }

    .vlees__graan {
        top: initial;
        right: -50px;
        bottom: calc(100% - 70px);
        left: initial;
        width: 250px;
    }

    .vlees__graan img {
        width: 100px;
    }

    .vlees__bg {
        right: -200px;
        bottom: -30px;
    }

    .vlees__intro__right .content__buttons {
        margin-top: 20px;
    }

    .vlees__intro {
        margin-bottom: 90px;

        h1,
        h2,
        h3,
        .row--form__title,
        h4,
        h5,
        h6 {
            font-size: 50px;
        }

        .hand {
            font-size: 56px;
        }
    }

    .full {
        margin-bottom: 70px;
    }

    .content__inner {
        position: relative;
        padding: 70px 70px 140px;
    }

    .content__vlees {
        max-width: 320px;
    }

    .content__block.content__block__vlees {
        margin-bottom: 130px;
    }

    .content__block {
        margin-bottom: 60px;

        h1,
        h2,
        h3,
        .row--form__title,
        h4,
        h5,
        h6 {
            font-size: 50px;
        }

        .hand {
            font-size: 56px;
        }
    }

    .footer__plant {
        bottom: calc(100% - 10px);
        max-width: 150px;
    }

    .footer__logo {
        padding: 100px 0;
    }

    .google {
        margin: -150px 0 100px;
    }

    .google__button {
        margin-top: 30px;
    }
}
