.footer {
    position: relative;
    color: #e6ebeb;
    background-color: #be6075;

    .footer__menu {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 25px;

        li {
            list-style: none;
            font-size: 12px;
            font-weight: 300;
            text-transform: uppercase;
            color: rgba(#e6ebeb, 0.5);
        }

        a {
            display: inline-block;
            font-size: 12px;
            font-weight: 300;
            text-transform: uppercase;
            color: rgba(#e6ebeb, 0.5);

            &::after {
                position: relative;
                top: -1px;
                padding: 0 15px;
                content: '|';
                opacity: 0.5;
            }
        }
    }
}

.footer__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 170px 0 140px;
}

.footer__bg {
    pointer-events: none;

    .container-fluid {
        position: relative;
    }

    svg {
        position: absolute;
        z-index: 99;
        top: -84.5px;
        right: calc(100% - 40px);
    }
}

.footer__plant {
    position: absolute;
    z-index: -1;
    right: 50px;
    bottom: calc(100% - 70px);
}

.footer__front__inner, {
    position: relative;
    z-index: 1;
    padding: 110px 350px 110px 105px;
    color: #fff;
    background-color: $primarycolor;

    .subtitle {
        margin-bottom: 30px;
        color: #7b9396;
    }

    .btn--border {
        margin-top: 20px;
        color: #fff;
        border-color: #1a5e6b;

        &::before {
            background-color: #00424e;
        }

        &::after {
            background-color: #00424e;
        }
    }
}

.footer__image__personen {
    position: absolute;
    z-index: 2;
    right: -175px;
    bottom: -87px;
}

.footer__image__graan {
    position: absolute;
    z-index: 0;
    top: -102px;
    right: -90px;
    transform: rotate(15deg);
}

.footer__front {
    margin-bottom: -290px;
}

.front-page-data {
    .footer__logo {
        padding: 465px 0 140px;
    }
}

.footer__front__text {
    position: relative;

    .hand {
        position: absolute;
        z-index: 3;
        top: -80px;
        left: 20px;
        color: #be6075;
    }
}
