.header__fix {
    .header__menu__fixed {
        top: 0;
        transition: all 300ms ease-in-out;
        opacity: 1;
    }
}

.header {
    position: relative;
    z-index: 99;
    height: 156px;
    background-color: transparent;
}

.header__lang {
    position: absolute;
    z-index: 99;
    display: inline-flex;
    margin-top: 0;
    margin-left: 320px;
    left: 100%;
    pointer-events: auto;

    a {
        color: $primarycolor;
    }
}

.lang-item {
    display: flex;
    margin-right: 35px;
    list-style: none;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;

    &::before {
        display: block;
        width: 5px;
        height: 100%;
        margin-right: 5px;
        content: '';
        background-repeat: no-repeat;
        background-position: center;
        background-size: 3px;
    }

    &.lang-item-nl {
        &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='13' viewBox='0 0 3 13'%3E%3Cg id='Group_172' data-name='Group 172' transform='translate(-2613 -95)'%3E%3Ccircle id='Ellipse_1' data-name='Ellipse 1' cx='1.5' cy='1.5' r='1.5' transform='translate(2613 95)' fill='%23bf5d5d'/%3E%3Cpath id='Path_7' data-name='Path 7' d='M1.5,0A1.5,1.5,0,1,1,0,1.5,1.5,1.5,0,0,1,1.5,0Z' transform='translate(2613 100)' fill='%23dcdcdc'/%3E%3Ccircle id='Ellipse_1-2' data-name='Ellipse 1' cx='1.5' cy='1.5' r='1.5' transform='translate(2613 105)' fill='%235d77bf'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }

    &.lang-item-de {
        &::before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='3' height='13' viewBox='0 0 3 13'%3E%3Cg id='Group_173' data-name='Group 173' transform='translate(-2610 -95)'%3E%3Ccircle id='Ellipse_1' data-name='Ellipse 1' cx='1.5' cy='1.5' r='1.5' transform='translate(2610 95)' fill='%23484343'/%3E%3Ccircle id='Ellipse_1-2' data-name='Ellipse 1' cx='1.5' cy='1.5' r='1.5' transform='translate(2610 100)' fill='%23bf5d5d'/%3E%3Ccircle id='Ellipse_1-3' data-name='Ellipse 1' cx='1.5' cy='1.5' r='1.5' transform='translate(2610 105)' fill='%23eaa400'/%3E%3C/g%3E%3C/svg%3E%0A");
        }
    }

    &:last-child {
        margin-right: 0;
    }
}

.header__logo {
    position: absolute;
    top: 0;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 264px;
    height: 313px;
    transform: translateX(-50%);
    background-color: $primarycolor;
    pointer-events: none;

    > a {
        pointer-events: auto;
    }
}

.header__menu {
    position: absolute;
    left: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 132px;
    padding: 9px 19px;
    cursor: pointer;
    background-color: #fff;
    pointer-events: all;
    box-shadow: 0 3px 6px rgba(#000, 0.16);
}

.header__menu__text {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: $primarycolor;
}

.header__menu__bars {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 14px;
    margin-right: 18px;

    span {
        display: inline-block;
        width: 17px;
        height: 2px;
        background-color: #be6075;

        &:nth-child(2) {
            width: 12px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.header__menu__fixed {
    position: fixed;
    z-index: 999;
    top: -50px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    transition: all 300ms ease-in-out;
    pointer-events: none;
    opacity: 0;

    .header__menu {
        position: relative;
        left: initial;
        margin: 0;
        pointer-events: all;
        background-color: #fff;
    }
}
