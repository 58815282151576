.intro__content {
    flex: 0 0 42%;
    max-width: 42%;
    padding-top: 90px;
    padding-bottom: 250px;

    p {
        padding-right: 105px;
    }

    .btn {
        margin-top: 50px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 50px;
        font-size: 63px;
    }
}

.intro {
    position: relative;
    overflow: hidden;

    > .container-fluid {
        z-index: 1;
    }
}

.intro__logo {
    position: absolute;
    z-index: 1;
    top: 115px;
    left: 100%;
    width: 344px;
    margin-left: -172px;
    pointer-events: none;

    svg {
        width: 344px;
        height: auto;
    }
}

.intro__bg {
    position: absolute;
    bottom: 0;
    left: 0;
}

.intro__text {
    max-width: 454px;
    margin-bottom: 50px;
}

.intro__side {
    flex: 0 0 58%;
    max-width: 58%;
    padding: 0 0 0;
}

.intro__side__content {
    position: relative;
    margin-left: 85px;
    padding: 90px 15px 90px 90px;
    color: #fff;
    background-color: $primarycolor;

    &::after {
        position: absolute;
        top: 0;
        left: 99%;
        width: 100vw;
        height: 100%;
        content: '';
        background-color: $primarycolor;
    }

    .btn {
        margin-top: 30px;
    }

    .subtitle {
        margin-bottom: 45px;
        color: #fff;
    }
}

.intro__side__image {
    margin-top: -80px;
}

.google {
    margin-bottom: 150px;
}

.google__inner {
    max-width: 1150px;
    margin: 0 auto;
    text-align: center;
}

.google__title {
    font-size: 30px;
}

.google__button {
    margin-top: 50px;
}
