.gform_footer {
    position: relative;
    z-index: 999;
    pointer-events: all;
}

.gfield {
    margin-bottom: 15px;

    .textarea.small {
        height: 100px;
    }

    input,
    select,
    textarea {
        width: 100%;
        height: 42px;
        padding: 10px 15px;
        font-size: 15px;
        border: 3px solid #e6ebeb;
        border-radius: 0;
        background-color: #fff;
        box-shadow: 0 0 0 transparent;

        &:focus {
            border-radius: 0;
            outline: none;
        }

        &::placeholder {
            color: #bebebe;
        }
    }

    select {
        padding: 0 15px;
    }

    textarea {
        height: auto;
    }

    .address_country {
        label {
            display: none;
        }
    }

    .ginput_container_date {
        position: relative;

        input {
            padding-left: 57px;
        }

        &::before {
            position: absolute;
            top: 1px;
            left: 1px;
            width: 41px;
            height: calc(100% - 2px);
            content: '';
            pointer-events: none;
            border-right: 1px solid #eee;
            border-radius: $border-radius 0 0 $border-radius;
            background-color: #eee;
            background-image: url('../../svg/calendar-alt-regular.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 15px 16px;
        }
    }

    .ginput_container_fileupload {
        position: relative;

        input {
            padding-right: 57px;
        }

        &::before {
            position: absolute;
            top: 1px;
            right: 1px;
            width: 41px;
            height: calc(100% - 2px);
            content: '';
            pointer-events: none;
            border-left: 1px solid #eee;
            border-radius: 0 $border-radius $border-radius 0;
            background-color: #ececec;
            background-image: url('../../svg/file-alt-solid.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 12px 16px;
        }

        .validation_message {
            display: none;
        }
    }
}

.gform_description {
    display: block;
    margin-bottom: 23px;
}

.gfield_label {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 700;
}

.gfield_checkbox {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
            position: relative;
            display: flex;
            align-items: flex-start;
            margin: 0;
            cursor: pointer;
            font-weight: 400;

            &::before,
            &::after {
                display: inline-block;
                content: " ";
            }

            &::before {
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                margin-top: 5px;
                margin-right: 15px;
                border: 1px solid #d5d5d5;
                border-radius: 3px;
            }
        }

        input {
            position: absolute;
            width: 0;
            visibility: hidden;
            opacity: 0;

            &:checked + label {
                &::after {
                    position: absolute;
                    top: 8px;
                    left: 2px;
                    width: 12px;
                    height: 9px;
                    content: "";
                    background-image: url("../../images/checkbox-solid.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 12px 9px;
                }
            }
        }
    }
}

.clear-multi {
    display: flex;
    margin: 0 -10px;

    > * {
        padding: 0 10px;
    }
}

.ginput_container_list {
    table {
        width: 100%;
    }

    tbody {
        display: flex;
        flex-direction: column;

        > tr {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;

            .gfield_list_cell {
                width: 100%;
            }

            .gfield_list_icons {
                display: flex;
                align-items: center;
                justify-content: center;

                > a {
                    margin: 0 3px;
                }
            }
        }
    }
}

.ginput_container_select {
    position: relative;
    margin-bottom: 5px;

    &::after {
        position: absolute;
        top: 50%;
        right: 15px;
        width: 10px;
        height: 16px;
        content: "";
        transform: translateY(-50%);
        background-image: url("../../images/angle-down-regular.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 10px 16px;
    }

    .gfield_select {
        padding-top: 7px;
        appearance: none;

        &::-ms-expand {
            display: none; /* remove default arrow in IE 10 and 11 */
        }
    }
}

.gfield_time_ampm {
    min-width: 85px;
    font-size: 14px;
}

.ginput_container_name {
    display: flex;
    margin: 0 -15px;

    > span {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;
    }

    label {
        display: block;
        order: 0;
    }
}

.ginput_container_post_image {
    input {
        padding: 4px;
    }
}

.ginput_container_fileupload {
    > input {
        padding: 4px;
    }
}

.ginput_container_address {
    > span {
        display: block;
        margin-bottom: 15px;

        > label {
            display: block;
            margin-top: 4px;
        }
    }
}

.gfield_contains_required {
    > .gfield_label {
        .gfield_required {
            color: #ff5757;
        }
    }
}

.ginput_container_consent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -15px;

    a {
        text-decoration: underline;
        color: #138e9d;
    }

    > input {
        width: auto;
        height: 23px;
        margin-right: 15px;
    }

    > label {
        margin-bottom: 0;
    }
}

.gfield_radio {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 10px;

        label {
            position: relative;
            display: flex;
            align-items: flex-start;
            margin: 0;
            cursor: pointer;
            font-weight: 400;

            &::before,
            &::after {
                display: inline-block;
                content: "";
            }

            &::before {
                width: 15px;
                min-width: 15px;
                height: 15px;
                min-height: 15px;
                margin-top: 5px;
                margin-right: 15px;
                border: 1px solid #d5d5d5;
                border-radius: 50px;
            }
        }

        input {
            position: absolute;
            width: 0;
            visibility: hidden;
            opacity: 0;

            &:checked + label {
                &::after {
                    position: absolute;
                    top: 8px;
                    left: 3px;
                    width: 9px;
                    height: 9px;
                    content: "";
                    border-radius: 50%;
                    background-color: $primarycolor;
                }
            }
        }
    }
}

.ginput_container_multiselect {
    select {
        height: auto;
    }
}

.gfield_required {
    margin-left: 5px;
}

.gform_fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    padding: 0;
    list-style: none;

    > li {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 15px;

        &.gform_hidden {
            position: absolute !important;
            overflow: hidden !important;
            height: 0 !important;
            margin: 0 !important;
            padding: 0 !important;
        }

        &.full {
            flex: auto;
            width: 100%;
            max-width: 100%;
            margin: 0 0 15px;
        }
    }
}

.validation_error {
    margin: 0 0 15px;
    font-size: 14px;
    font-weight: 500;
    color: #ef5350;
}

.validation_message {
    position: relative;
    display: flex;
    align-items: center;
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: 500;
    color: #ef5350;

    &::after {
        position: relative;
        width: 14px;
        height: 12px;
        margin: 0 0 0 auto;
        content: '';
        background-image: url('../../images/exclamation-triangle-solid-red.svg');
        background-size: 14px 12px;
    }
}

.gform_button {
    margin: 30px 0 0;
    color: #fff;
    background-color: #be6075;

    @extend .btn;

    &:hover,
    &:focus {
        color: #fff !important;
        background-color: darken(#be6075, 5) !important;
    }
}

.gform_ajax_spinner {
    position: relative;
    top: 6px;
    left: 10px;
    width: 30px;
    height: 30px;
}

#ui-datepicker-div {
    display: none;
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
    box-shadow: (0 3px 5px 0 rgba(50, 50, 50, 0.75));

    .ui-icon {
        cursor: pointer;
        font-size: 0;
        color: transparent;
    }

    .ui-icon::before {
        font-size: 18px;
    }

    .ui-datepicker-prev {
        display: flex;
        align-items: center;
        justify-content: center;
        float: left;
        width: 10%;
        cursor: pointer;

        .ui-icon::before {
            position: relative;
            float: left;
            width: 10px;
            height: 19px;
            content: '';
            background-image: url('../../images/angle-left-regular.svg');
            background-repeat: no-repeat;
            background-size: 9px;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-next {
        display: flex;
        align-items: center;
        justify-content: center;
        float: right;
        width: 10%;
        cursor: pointer;

        .ui-icon::before {
            position: relative;
            float: right;
            width: 10px;
            height: 19px;
            content: '';
            background-image: url('../../images/angle-right-regular.svg');
            background-repeat: no-repeat;
            background-size: 9px;
        }

        &.ui-state-disabled {
            display: none;
        }
    }

    .ui-datepicker-header {
        display: block;
        float: left;
        width: 100%;
        margin-top: 12px;
    }

    .ui-datepicker-title {
        select {
            float: left;
            width: 70%;
        }

        .ui-datepicker-month {
            margin: 0 5% 6px;
            padding: 3px;
            border: 1px solid #cfcfcf;
            border-radius: 4px;
        }

        .ui-datepicker-year {
            margin: 0 15% 6px;
            padding: 3px;
            border: 1px solid #cfcfcf;
            border-radius: 4px;
        }
    }

    .ui-datepicker-today {
        border-radius: 4px;
        background-color: #e6eef1;

        a {
            color: #2a4982;
        }
    }

    table {
        width: 100%;

        td,
        th {
            text-align: center;
        }

        td {
            a {
                display: block;
                padding: 5px;
                border-radius: 4px;

                &:hover {
                    text-decoration: none;
                    color: #fff;
                    background-color: $primarycolor;
                }

                &.ui-state-active {
                    text-decoration: none;
                    color: #fff;
                    background-color: $primarycolor;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .gform_fields > li {
        flex: 0 0 100%;
        max-width: 100%;
    }
}
