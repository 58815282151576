@media screen and (max-width: 991px) {
    .galerij__intro {
        margin: 80px 0 60px;
    }

    .header__logo {
        width: 200px;
        height: 170px;
    }

    .banner__content__inner {
        max-width: 420px;
        padding: 40px 0 0;
    }

    h1,
    h2,
    h3,
    .row--form__title,
    h4,
    h5,
    h6 {
        margin-bottom: 10px;
        font-size: 40px;
    }

    .hand {
        margin-top: -5px;
        font-size: 40px;
    }

    .title__small h1,
    .title__small h2,
    .title__small h3,
    .title__small .row--form__title,
    .title__small h4,
    .title__small h5,
    .title__small h6 {
        margin-bottom: 15px;
        font-size: 40px;
    }

    .title__small .hand {
        margin-top: -5px;
        font-size: 40px;
    }

    .kiezen__inner {
        padding: 40px;
    }

    .faq__title {
        margin-bottom: 15px;
        font-size: 40px;
    }

    .faq__title .hand {
        font-size: 40px;
    }

    .faq__vragen {
        margin-bottom: 40px;
    }

    .faq__inner {
        padding: 35px 30px;
    }

    .vlees__plant img {
        max-width: 175px;
    }

    .vlees__item__bot__title {
        font-size: 30px;
    }

    .vlees__item__bot__title .hand {
        font-size: 30px;
    }

    .template-heerlijk-vlees-data .banner {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .vlees__bot__info {
        padding: 0 0 10px;
    }

    .blog__single {
        padding: 30px 30px 40px;
    }

    .blog__single__title h1 {
        font-size: 24px;
    }

    .blog__single__title {
        margin-bottom: 30px;
    }

    .single__img__nieuws {
        margin-bottom: 25px;
    }

    .single__buttons {
        margin-top: 10px;
    }

    .single__content {
        padding: 50px 0 150px;
    }

    .single__date {
        width: 165px;
        height: 42px;
        font-size: 14px;
    }

    .vlees__item__bot {
        padding: 30px;
    }

    .template-contact-data .banner__content {
        flex: 0 0 60%;
        max-width: 60%;
    }

    .banner__contact {
        left: -250px;
    }

    .omgeving__inner .hand {
        font-size: 54px;
    }

    .banner__graan {
        position: absolute;
        right: 40px;
        bottom: -20px;
        width: 60px;
    }

    .intro__content p {
        padding-right: 0;
    }

    .menu__number svg {
        width: 100px;
        height: 78px;
    }

    .menu__close {
        top: 30px;
        right: 30px;
    }

    .menu__inner {
        padding: 30px;
    }

    .menu__center {
        max-width: 600px;
    }

    .intro__content h1,
    .intro__content h2,
    .intro__content h3,
    .intro__content .row--form__title,
    .intro__content h4,
    .intro__content h5,
    .intro__content h6 {
        margin-bottom: 10px;
        font-size: 34px;
    }

    .intro__content h1 .hand,
    .intro__content h2 .hand,
    .intro__content h3 .hand,
    .intro__content .row--form__title .hand,
    .intro__content h4 .hand,
    .intro__content h5 .hand,
    .intro__content h6 .hand {
        margin-top: -5px;
        font-size: 36px;
    }

    .intro__content .btn,
    .intro__content .comment-form input[type="submit"],
    .comment-form .intro__content input[type="submit"],
    .intro__content .gform_button {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .blog__slider__item {
        width: 50%;
    }

    .blog__slider__title h1,
    .blog__slider__title h2,
    .blog__slider__title h3,
    .blog__slider__title .row--form__title,
    .blog__slider__title h4,
    .blog__slider__title h5,
    .blog__slider__title h6 {
        margin-bottom: 10px;
        font-size: 40px;
    }

    .blog__slider__title h1 .hand,
    .blog__slider__title h2 .hand,
    .blog__slider__title h3 .hand,
    .blog__slider__title .row--form__title .hand,
    .blog__slider__title h4 .hand,
    .blog__slider__title h5 .hand,
    .blog__slider__title h6 .hand {
        font-size: 40px;
    }

    .intro__side__content .subtitle {
        margin-bottom: 10px;
    }

    .blog__slider__block {
        padding: 50px 0 100px;
    }

    .vlees__title h1,
    .vlees__title h2,
    .vlees__title h3,
    .vlees__title .row--form__title,
    .vlees__title h4,
    .vlees__title h5,
    .vlees__title h6 {
        margin-bottom: 10px;
        font-size: 40px;
    }

    .vlees__title h1 .hand,
    .vlees__title h2 .hand,
    .vlees__title h3 .hand,
    .vlees__title .row--form__title .hand,
    .vlees__title h4 .hand,
    .vlees__title h5 .hand,
    .vlees__title h6 .hand {
        font-size: 40px;
    }

    .content__left {
        flex: 0 0 50%;
        max-width: 50%;
    }

    .content__right {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 20px;
    }

    .content__block .btn,
    .content__block .comment-form input[type="submit"],
    .comment-form .content__block input[type="submit"],
    .content__block .gform_button, {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .image__block__right .btn,
    .image__block__right .comment-form input[type="submit"],
    .comment-form .image__block__right input[type="submit"],
    .image__block__right .gform_button {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .footer__image__personen {
        order: 0;
        margin: 0 0 30px;
    }

    .footer__front__inner .subtitle {
        order: 1;
        margin-bottom: 10px;
    }

    .footer__front__inner p {
        order: 2;
    }

    .footer__front__inner .btn--border {
        order: 3;
        margin: 5px 0;
    }

    .vlees__intro h1,
    .vlees__intro h2,
    .vlees__intro h3,
    .vlees__intro .row--form__title,
    .vlees__intro h4,
    .vlees__intro h5,
    .vlees__intro h6 {
        font-size: 35px;
    }

    .vlees__intro .hand {
        font-size: 35px;
    }

    .content__block h1,
    .content__block h2,
    .content__block h3,
    .content__block .row--form__title,
    .content__block h4,
    .content__block h5,
    .content__block h6 {
        margin-bottom: 20px;
        font-size: 40px;
    }

    .content__block .hand {
        font-size: 40px;
    }

    .content__block.content__block__vlees .btn,
    .content__block.content__block__vlees .comment-form input[type="submit"],
    .comment-form .content__block.content__block__vlees input[type="submit"],
    .content__block.content__block__vlees .gform_button {
        margin-top: 10px;
    }

    .content__inner {
        padding: 40px 40px 75px;
    }

    .archive__content {
        padding: 20px 0 0;
    }

    .header__logo img {
        width: 60%;
    }

    .body__logo {
        height: 412px;
    }

    .body__logo.left__center svg {
        left: -77px;
        width: 154px;
        height: 206px;
    }

    .body__logo.right__center svg {
        right: -77px;
        width: 154px;
        height: 206px;
    }

    .order__form {
        padding: 30px;
    }

    .order__block__title {
        font-size: 14px;
    }

    .order__count {
        width: 75px;
    }

    .order__default {
        width: 75px;
    }

    .order__kg {
        width: 50px;
    }

    .order__price {
        width: 80px;
    }

    .order__table--small .order__count {
        width: 70px;
    }

    .table th,
    .table td {
        font-size: 13px;
    }

    .order__count input {
        width: 50px;
        padding-right: 0;
    }

    .form__block--address {
        margin-top: 0;
    }

    .form__info__block {
        margin-bottom: 30px;
        font-size: 14px;
    }

    .app__pers {
        font-size: 26px;
    }

    .app__type {
        font-size: 50px;
    }

    .app__tabs .nav-tabs .nav-link {
        font-size: 14px;
        padding: 5px 10px;
    }
}
