@media screen and (max-width: 767px) {
    .header {
        position: absolute;
        left: 0;
        width: 100%;
        padding: 0 35px;

        .container-fluid {
            display: flex;
            align-items: flex-start;
        }
    }

    .galerij__intro {
        margin: 160px 33px 60px;
    }

    .template-galerij-data .row--gallery {
        padding: 20px !important;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
    }

    .container-fluid {
        padding: 0;
    }

    .row {
        margin-right: 0;
        margin-left: 0;
    }

    /* stylelint-disable */
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        padding-right: 0;
        padding-left: 0;
    }
    /* stylelint-enable */

    .banner__image {
        img {
            width: 100%;
        }
    }

    .header__logo {
        position: relative;
        left: 0;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        order: 1;
        width: 100%;
        height: 133px;
        margin-left: 0;
        transform: translate(0);
        background-color: transparent;

        > a {
            width: 113px;
            height: 100%;
            transition: all 0ms ease-in-out;
            background-color: $primarycolor;
        }
    }

    .lang-item {
        margin-right: 15px;
        padding: 2px 6px;
        border: 2px solid #e6ebeb;
        background-color: #fff;
    }

    .header__menu {
        position: relative;
        left: initial;
        margin: 0;
    }

    .header__logo img {
        width: 66px;
        height: auto;
    }

    .header__lang {
        position: absolute;
        top: 22px;
        right: 0;
        left: initial;
        order: 2;
        margin: 0;
    }

    .body__logo.right__top {
        top: 10%;
        left: 0;
    }

    .body__logo.right__top svg {
        right: -70px;
        width: 140px;
    }

    .banner__content__inner {
        max-width: 100%;
        margin-top: -10px;
        padding: 0 30px;
    }

    .banner__bg {
        left: -200px;
    }

    h1,
    h2,
    h3,
    .row--form__title,
    h4,
    h5,
    h6 {
        margin-bottom: 20px;
        font-size: 40px;
        text-align: center;
    }

    .faq {
        margin-bottom: 0;
    }

    .vlees__item__top {
        margin-bottom: 10px;
    }

    .template-contact-data .banner {
        padding-top: 200px;
        padding-bottom: 40px;
    }

    .template-contact-data .banner__content {
        flex: 0 0 70%;
        max-width: 70%;
    }

    .contact__form__inner {
        margin: 0 30px 130px;
        padding: 20px 30px;
    }

    .kiezen {
        margin-bottom: 40px;
        padding: 0 30px;
    }

    .intro__content {
        flex: 0 0 100%;
        order: 2;
        max-width: 100%;
        padding: 40px 30px 100px;
    }

    .intro__side {
        flex: 0 0 100%;
        order: 1;
        max-width: 100%;
    }

    .vlees__item {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 30px;
        padding: 0 30px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .vlees__cow {
        padding: 55px 10px 15px;
    }

    .vlees__item__bot__title {
        text-align: left;
    }

    .blog__single__title {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.4;
            text-align: left;
        }
    }

    .content__block.content__block__vlees {
        margin-bottom: 0;
    }

    .single__content {
        padding: 170px 30px;
    }

    .blog__single__title h1 {
        font-size: 22px;
    }

    .single__img__nieuws {
        margin-bottom: 25px;
    }

    .banner__graan {
        bottom: -70px;
        width: 105px;
    }

    .banner {
        padding-bottom: 40px;
    }

    .subtitle {
        margin-bottom: 15px;
        font-size: 16px;
        text-align: center;
    }

    .intro__side__content .subtitle {
        margin-bottom: 15px;
    }

    .intro__side__content {
        padding: 40px 30px 30px;
    }

    .intro__side__image {
        margin-top: 0;
    }

    .intro__bg {
        bottom: 0;
        overflow: hidden;
        height: 60px;
    }

    .intro {
        overflow: initial;
    }

    .blog__slider__block {
        padding: 40px 30px 0;
    }

    .blog__slider__outer {
        margin: 0 -10px;
        padding: 0;
    }

    .vlees__content {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 40px 30px 60px;
    }

    .vlees__block {
        margin-top: 0;
        margin-bottom: 40px;
    }

    .vlees__image {
        position: relative;
        width: 100%;
        height: 300px;
    }

    .vlees__lap {
        width: 285px;
        height: 250px;
        margin-right: 0;
        margin-bottom: -110px;
    }

    .vlees__title h1,
    .vlees__title h2,
    .vlees__title h3,
    .vlees__title .row--form__title,
    .vlees__title h4,
    .vlees__title h5,
    .vlees__title h6 {
        margin-bottom: 20px;
        font-size: 34px;
    }

    .vlees__title h1 .hand,
    .vlees__title h2 .hand,
    .vlees__title h3 .hand,
    .vlees__title .row--form__title .hand,
    .vlees__title h4 .hand,
    .vlees__title h5 .hand,
    .vlees__title h6 .hand {
        font-size: 34px;
    }

    .blog__slider__title h1,
    .blog__slider__title h2,
    .blog__slider__title h3,
    .blog__slider__title .row--form__title,
    .blog__slider__title h4,
    .blog__slider__title h5,
    .blog__slider__title h6 {
        margin-bottom: 20px;
        font-size: 34px;
        text-align: left;
    }

    .vlees__title .subtitle {
        position: absolute;
        top: -130px;
        right: 0;
        width: 155px;
        line-height: 1.6;
        text-align: right;
        white-space: pre-wrap;
        color: #fff;
    }

    .content__block {
        margin: 0;
        padding: 40px 30px;
    }

    .content__block__vlees {
        padding: 0;
    }

    .footer__plant {
        max-width: 120px;
    }

    .omgeving__inner .hand {
        font-size: 44px;
    }

    .omgeving__inner {
        margin-bottom: 30px;
        padding: 40px 30px;
    }

    .contact__form__inner .subtitle {
        margin-bottom: 10px;
    }

    .omgeving__inner h1,
    .omgeving__inner h2,
    .omgeving__inner h3,
    .omgeving__inner .row--form__title,
    .omgeving__inner h4,
    .omgeving__inner h5,
    .omgeving__inner h6 {
        margin-bottom: 10px;
        font-size: 32px;
        text-align: left;
    }

    .omgeving {
        margin-bottom: 60px;
        padding: 0 30px;
    }

    .footer__logo {
        padding: 50px 0;
    }

    .content__image {
        margin-top: 20px;
    }

    .blog__single {
        margin-bottom: 130px;
    }

    .content__vlees {
        position: relative;
        top: initial;
        left: initial;
        max-width: 200px;
        margin: 25px auto 0;
        transform: none;
    }

    .content__inner {
        padding: 40px 30px;
    }

    .content__left {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0 30px 40px;
    }

    .content__right {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .image__block__left {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .image__block__right {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 40px 30px;
    }

    .image__block {
        display: none;
        margin-top: 0;
        margin-bottom: 0;
    }

    .footer__image__graan {
        display: none;
    }

    .footer__front {
        margin: 0;
        background-color: #00424e;
    }

    .footer__front__text .hand {
        margin: 0;
        padding: 40px 30px 0;
        font-size: 62px;
        line-height: 1.3;
        text-align: center;
    }

    .menu__close svg {
        width: 22px;
        height: 22px;
    }

    .menu__close {
        width: 60px;
        height: 60px;
    }

    .menu__number svg {
        width: 70px;
        height: 78px;
    }

    .menu__inner .nav .menu-item a {
        padding: 5px 10px;
        font-size: 16px;
    }

    .menu__image {
        height: 350px;
    }

    .menu__center {
        max-width: 475px;
    }

    .footer__front__inner {
        align-items: center;
        padding: 10px 30px;
        text-align: center;
    }

    .footer__front__inner .subtitle {
        margin-bottom: 20px;
    }

    .footer__image__personen {
        order: 4;
        margin: 60px auto -102.5px;
    }

    .footer .footer__menu a::after {
        padding: 0 5px;
    }

    .front-page-data .footer__logo {
        padding: 170px 0 80px;
    }

    .front-page-data {
        .content__block {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .footer .footer__menu .menu-item:last-child a::after {
        display: none;
    }

    .vlees__intro__left {
        position: relative;
        width: 100%;
        height: 300px;
    }

    .vlees__inner {
        padding: 40px 30px 60px;
    }

    .vlees__intro__right {
        padding: 0;
    }

    .vlees__intro__right p {
        max-width: initial;
    }

    .vlees__intro {
        margin-bottom: 0;
    }

    .full {
        margin-bottom: 0;
    }

    .archive__list {
        .row {
            margin-right: -20px;
            margin-left: -20px;
        }

        .container-fluid {
            padding-right: 40px;
            padding-left: 40px;
        }

        /* stylelint-disable */
        .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
            padding-right: 20px;
            padding-left: 20px;
        }

        /* stylelint-enable */
    }

    .blog__content {
        padding: 0 20px 0;
    }

    .col--form {
        padding-right: 10px !important;
        padding-left: 10px !important;
    }

    .order__form__half {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .form__block {
        margin-bottom: 25px;
    }

    .col--form--required {
        margin-bottom: 0;
    }

    .modal-content {
        width: 90%;
        margin: 0 auto;
        font-size: 14px;
    }

    .modal-body {
        padding: 40px 50px;
    }

    .modal__bot ul li {
        width: 100%;
        margin: 0;
        font-size: 14px;
    }

    .modal__option {
        margin-bottom: 0;
    }

    .modal__option__list {
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .modal-steak svg {
        width: 140px;
    }

    .modal__bot ul {
        margin: 0;
        padding: 0;
    }

    .modal__bot .hand {
        font-size: 42px;
    }

    .modal__bot h1,
    .modal__bot h2,
    .modal__bot h3,
    .modal__bot .row--form__title,
    .modal__bot h4,
    .modal__bot h5,
    .modal__bot h6 {
        font-size: 32px;
    }

    .order {
        padding: 15px 0 40px;
    }

    .order__form {
        margin: 0 40px;
        padding: 25px;

        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }

        ::-webkit-scrollbar:vertical {
            width: 12px;
        }

        ::-webkit-scrollbar:horizontal {
            height: 12px;
        }

        ::-webkit-scrollbar-thumb {
            border: 2px solid #fff;
            border-radius: 10px;
            background-color: rgba(0, 0, 0, 0.5);
        }

        ::-webkit-scrollbar-track {
            border-radius: 10px;
            background-color: #fff;
        }
    }

    .order__form .form-control {
        min-height: 42px;
        font-size: 14px;
    }

    .order__count input {
        height: 28px;
        min-height: 28px !important;
    }

    .order__package {
        min-width: 158px;
        white-space: normal !important;
    }

    .order__small {
        white-space: normal !important;
    }

    .order__small span {
        max-width: 100%;
    }

    .table th,
    .table td {
        white-space: nowrap;
    }

    .app__inner {
        gap: 60px;
        margin-bottom: 40px;
    }

    .app__item {
        flex-direction: column;
        padding: 0 30px;
    }

    .app__image {
        flex: 0 0 100%;
        max-width: 100%;
        order: 1 !important;
    }

    .app__content {
        flex: 0 0 100%;
        max-width: 100%;
        order: 2 !important;
        margin-top: 20px;
    }
}
